<template>
  <div>
    <el-form ref="form" :model="form" style="margin-bottom: 20px">
      <el-descriptions direction="vertical" :column="6" border style="width: 100%">
        <el-descriptions-item label="单据编号">
          <el-form-item prop="expenseNumber" ref="expenseNumber">
            {{ form.expenseNumber }}
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item label="填报人">
          <el-form-item prop="createName" ref="createName">
            {{ form.createName }}
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item label="申请日期">
          <el-form-item prop="taskDate" ref="taskDate">
            {{ form.taskDate | dateFormat }}
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item label="申请人">
          <el-form-item prop="applyName" ref="applyName">{{ form.applyName }} </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item label="发生日期">
          <el-form-item prop="occurDate" ref="occurDate">
            {{ form.occurDate | dateFormat }}
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item
          label="登记类型"
          label-class-name="businessOutSource"
          content-class-name="businessOutSource"
        >
          <el-form-item prop="registrationType" ref="registrationType">
            {{ form.registrationType | dict(registrationTypeList) }}
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item label="申请单位">
          <el-form-item prop="companyType" ref="companyType">
            {{ form.companyType | dict(companyTypeList) }}
          </el-form-item>
        </el-descriptions-item>

        <el-descriptions-item
          :span="1"
          label-class-name="businessOutSource"
          content-class-name="businessOutSource"
        >
          <template slot="label">
            <span style="color: #000">费用类别</span>
          </template>
          <el-form-item prop="expenseType" ref="expenseType" style="color: #000; font-size: 14px">
            {{ form.expenseType | dict(expenseTypeList) }}
          </el-form-item>
        </el-descriptions-item>

        <template v-if="form.expenseType == 'PROJECT'">
          <el-descriptions-item label="费用所属项目" :span="4">
            <el-form-item prop="expenseBelongId" ref="expenseBelongId">
              {{ form.expenseBelongName }}
            </el-form-item>
          </el-descriptions-item>
        </template>

        <template v-if="form.expenseType == 'BUSINESS'">
          <el-descriptions-item :span="1">
            <template slot="label"> 支出类型 </template>
            <span>
              {{ form.disbursementType | dict(disbursementTypeList) }}
            </span>
          </el-descriptions-item>

          <el-descriptions-item :span="3" contentStyle="min-width:300px;">
            <template slot="label">
              <span v-if="form.disbursementType == 'UNDEFINED_RATIFICATION_IMPLEMENT'"
                >商务说明</span
              >
              <span v-else>费用所属商务</span>
            </template>
            <template v-if="form.disbursementType == 'UNDEFINED_RATIFICATION_IMPLEMENT'">
              <span style="display: inline-block; line-height: 40px; height: 40px">{{
                form.businessContent
              }}</span>
            </template>
            <template v-else>
              <span>
                {{ form.expenseBelongName }}
              </span>
            </template>
          </el-descriptions-item>
        </template>

        <template v-if="form.expenseType == 'PUBLIC_SPENDING'">
          <el-descriptions-item label="费用所属部门" :span="4">
            <el-form-item prop="expenseBelongId" ref="expenseBelongId">
              {{ form.expenseBelongName }}
            </el-form-item>
          </el-descriptions-item>
        </template>

        <el-descriptions-item label="支出说明" :span="6">
          <el-form-item prop="expenditureExplain" ref="expenditureExplain"
            >{{ form.expenditureExplain }}
          </el-form-item>
        </el-descriptions-item>
      </el-descriptions>
    </el-form>
    <el-table border :data="form.detailList">
      <el-table-column prop="cost" label="金额 (元)" :show-overflow-tooltip="false">
        <template slot-scope="scope">{{ scope.row.cost | applyAmount }} </template>
      </el-table-column>
      <el-table-column
        scoped-slot
        prop="contentDetail"
        :label="'事由附件详细说明\n（含参与人员）'"
        :show-overflow-tooltip="false"
      >
        <template slot-scope="scope">{{ scope.row.contentDetail }} </template>
      </el-table-column>
      <el-table-column align="left" label="附件上传" :show-overflow-tooltip="false">
        <template slot-scope="scope">
          <div v-for="(n, index) in scope.row.expenseFileList" :key="index" style="margin-top: 5px">
            <a
              class="fj-text"
              target="_blank"
              style="text-decoration: revert; color: #409eff; padding-right: 8px"
              :href="`${filepath}${n.filePath}`"
            >
              {{ n.fileName }}
            </a>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-descriptions title="" :column="2" border>
      <el-descriptions-item label="合计" labelStyle="width:15%">
        {{ amount | applyAmount }}
      </el-descriptions-item>
      <el-descriptions-item label="金额大写" labelStyle="width:15%">
        {{ amount | numberParseChina }}
      </el-descriptions-item>
    </el-descriptions>
    <OpinionArea
      ref="opinionArea"
      :title="'登记进度'"
      :procInstId="form.processInstId"
    ></OpinionArea>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ChefRegistration',
  components: {
    OpinionArea: () => import('@/components/OpinionArea.vue'),
  },
  props: {
    form: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
    amount() {
      let num = 0
      if (this.form.detailList) {
        this.form.detailList.forEach(v => {
          num += Number(String(v.cost).replace(/,/gi, ''))
        })
      }
      return num
    },
  },
  data() {
    return {
      companyTypeList: [],
      expenseTypeList: [],
      registrationTypeList: [],
      hospitalityGradeList: [],
      disbursementTypeList: [],
      filepath: process.env.VUE_APP_FILEPATH,
    }
  },
  filters: {
    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },
  },
  created() {
    this.getType()
  },
  methods: {
    getType() {
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.companyTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('EXPENSE_TYPE', true)
        .then(res => {
          this.expenseTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('REGISTRATION_TYPE', true)
        .then(res => {
          this.registrationTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('HOSPITALITY_GRADE', true)
        .then(res => {
          this.hospitalityGradeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_DISBURSEMENT_TYPE', true)
        .then(res => {
          this.disbursementTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
/deep/.el-form-item {
  margin-bottom: 0;
}
/deep/.el-descriptions__body .el-descriptions__table .el-descriptions-item__cell {
  padding: 8px 10px;
}
</style>
